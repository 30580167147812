/* Container that holds all cards */
.cards {
    display: flex;
    justify-content: center;
    padding: 2rem;
}

/* Container for the grid layout */
.cards .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-auto-rows: auto; 
    gap: 4rem; 
    max-width: 800px; 
    width: 100%;
    margin: 1rem 0;
}

/* Individual card styles */
.cards .card-container .card {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.5s, box-shadow 0.5s;
}

/* Image inside the card */
.cards .card-container .card .cardimg {
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
}

/* Card title */
.title {
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    color: #000000;
    margin: 0 0 10px 0;
    font-size: 20px;
}

/* Card description */
.description {
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    color: #000000;
    margin: 0;
    font-size: 16px;
}

/* Hover effect for card */
.cards .card-container .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive Design for Tablet and Small Desktop Devices */
@media (max-width: 1024px) {
    .cards .card-container {
        grid-template-columns: 1fr; 
        gap: 1rem;
    }

    .cards .card-container .card {
        padding: 1.5rem; 
    }

    .cards .card-container .card {
        max-width: 50%; 
        margin: 0 auto; /* Center the card */
        height: 80%;
    }
}

/* Responsive Design for Mobile Devices */
@media (max-width: 768px) {
    .cards {
        padding: 1rem;
    }

    .cards .card-container {
        grid-template-columns: 1fr; 
        gap: 3rem;
        width: 70%; 
    }

    .cards .card-container .card {
        max-width: 50%; 
        margin: 0 auto; 
        height: 80%;
    }

    .cards .card-container .card .cardimg {
        width: 3rem;
        height: 3rem;
    }

    .title {
        font-size: 16px; 
    }

    .description {
        font-size: 12px; 
    }
}

/* Responsive Design for Small Mobile Devices */
@media (max-width: 480px) {
    .cards {
        padding: 0.5rem;
    }

    .cards .card-container {
        gap: 0.5rem; 
    }

    .cards .card-container .card {
        padding: 1rem; 
        width: 100%; 
    }

    .title {
        font-size: 14px; 
    }

    .description {
        font-size: 10px; 
    }
}
