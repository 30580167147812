
  /* src/components/Navbar.css */

html {
  scroll-behavior: smooth;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.navbar-logo{
  display: flex;
}

.logoN{
  width: 10rem;
  height: 25px;
  margin-top: 6px;
  margin-left: 0px;
}

.nav-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav-links a:hover {
  color: #4774E6;
}

.nav-links a {
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  justify-content: space-around;
  text-decoration: none;
  color:#000000;
  font-size: 18px;
}

.menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 4px;
}

.menu .line {
  width: 25px;
  height: 3px;
  background-color: black;
}

.googlePlay-button{
  width: 10rem;
  height: 5rem;
  cursor: pointer;
  border: none;
}

nav ul li a.active {
  color: #4774E6; 
}



/* Hiding all navbar items on smaller screens */
@media (max-width: 768px) {

  .logoN{
    width: 8rem;
    height: 18px;
    margin-top: 6px;
    margin-left: 0px;
  }

  .nav-links a{
      display: none; 
  }

  /* Show only googlePlay button*/
  .navbar .googlePlay-button{
      display: block; 
      width: 7.5rem;
      height: 5.5rem;
  }
}