/*body {
  margin: 0;
  font-family: 'Arial, sans-serif';
  background-color: #121212;
  color: white;
}

.container {
  padding: 20px;
}*/

/* src/App.css */

html, body{
    margin: 0 0 ;
}

.App{
  background-image: url("../src/assets/background1/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

