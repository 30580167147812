

.personalization .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal-width columns */
    gap: 20px; /* Space between the cards */
    padding: 20px;
}

.personalization .grid-container .gridcard {
    order: 2;
    padding: 8rem;
    border-radius: 8px;
    text-align: left;
}

.personalization .grid-container .gridcard1{
    order: 1;
  background-image: url('../assets/sections/personalizationImg.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  align-items: center;
  height: 70vh;
}

.personalization .grid-container .gridcard .title {
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    color: #000000;
    margin: 0 0 10px 0;
    font-size: 24px;
  }

  .personalization .grid-container .gridcard .description {
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    color: #000000;
    margin: 0;
    font-size: 16px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .personalization .grid-container {
      grid-template-columns: 1fr; 
  }

  .personalization .grid-container .gridcard {
    order: 2;
      padding: 6rem;

  }


  .personalization .grid-container .gridcard1 {
    order: 1;
    padding: 5rem;
    height: 40vh;
}

  .personalization .grid-container .gridcard .title {
    font-size: 35px;
    margin-right: 35px;

  }

  .personalization .grid-container .gridcard .description {
      font-size: 25px; 
  }
}

@media (max-width: 768px) {
  .personalization .grid-container .gridcard,
  .personalization .grid-container .gridcard1 {
      padding: 3rem;
  }

  .personalization .grid-container .gridcard1 {
      height: 50vh;
  }

  .personalization .grid-container .gridcard .title {
      font-size: 18px; 
  }

  .personalization .grid-container .gridcard .description {
      font-size: 12px;
  }
}

@media (max-width: 480px) {
  .personalization .grid-container .gridcard,
  .personalization .grid-container .gridcard1 {
      padding: 2rem;
  }

  .personalization .grid-container .gridcard1 {
      height: 26vh; 
  }

  .personalization .grid-container .gridcard .title {
      font-size: 16px; 
  }

  .personalization .grid-container .gridcard .description {
      font-size: 10px; 
  }
}