/* General styles for header container */
.home_title .header {
  align-self: center;
  margin-left: auto; 
  margin-right: auto;
  text-align: center;
  width: 90%; 
  max-width: 800px; 
  padding: 1rem; 
}

/* Heading margin adjustments */
.home_title .header .heading1 {
  margin-top: 5rem;
  margin-bottom: 0;
}

/* Styles for .skill class */
.skill {
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-size: 6vw; /* Use viewport width for responsive font size */
  color: #444444;
}

/* Styles for .crunch class */
.crunch {
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-size: 6vw; 
  color: #4774E6;
}


.heading2 {
  color: #000000;
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 2rem; 
  margin-bottom: 2rem;
}

/* Styles for .heading3 class */
.heading3 {
  color: #000000;
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem; 
  margin: 0 0;
  margin-bottom: 3rem;
}

/* Styles for download button */
.download-button {
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: #4774E6;
  font-size: 1.5rem; 
  font-weight: bold;
  color: #ffffff;
  padding: 0.5rem 1.5rem; 
  cursor: pointer;
  border: #4774E6;
  border-radius: 30px;
}

.download-button:hover {
  background-color: white;
  border: solid;
  border-color: #4774E6;
  color: #4774E6;
}

.download-button i {
  color: white;
}

.download-button:hover i {
  color: #4774E6;
}

p {
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #000000;
}

/* Responsive Design */

/* Adjustments for Tablets and Larger Screens */
@media (max-width: 1024px) {
  .home_title .header {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
  }

  .skill, .crunch {
      font-size: 5vw; 
  }

  .heading2 {
      font-size: 1.75rem;
  }

  .heading3 {
      font-size: 1.125rem;
  }

  .download-button {
      font-size: 1.25rem;
      padding: 0.5rem 1.25rem;
  }
}

/* Adjustments for Mobile Devices */
@media (max-width: 768px) {
  .home_title .header {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      padding: 1rem;
  }

  .skill, .crunch {
      font-size: 7vw;
  }

  .heading1 {
      margin-top: 3rem; 
  }

  .heading2 {
      font-size: 1.5rem; 
  }

  .heading3 {
      font-size: 1rem; 
  }

  .download-button {
      font-size: 1rem;
      padding: 0.75rem 1.5rem; 
  }
}

/* Adjustments for Small Mobile Devices */
@media (max-width: 480px) {
  .home_title .header {
      width: 80%; 
      padding: 0.5rem; 
  }

  .skill, .crunch {
      font-size: 8vw; 
  }

  .heading1 {
      margin-top: 2rem; 
  }

  .heading2 {
      font-size: 1.25rem; 
  }

  .heading3 {
      font-size: 0.875rem; 
  }

  .download-button {
      font-size: 0.875rem; 
      padding: 0.5rem 1rem;
  }
}
