
  /* src/components/Legal.css */
.legal {
  font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
  color: #000000;
}

.legal h2 {
  font-weight: 200;
  font-size: 2em;
}

.legal ul {
  list-style: disc;
  padding: 20px;
}

.legal li {
  margin-bottom: 5px;
  cursor: pointer;
}

.legal li::marker {
  color: #4774E6;
}

@media (max-width: 768px) {
  .legal h2 {
    font-size: 1.5rem;
  }

  .legal ul {
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    font-size: 10px;
    padding: 5px;
  }
  
  .legal li {
    margin: 5px 15px;
  }
  
}