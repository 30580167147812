
  /* src/components/About.css */
.about {
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  color: #000000;
  border-bottom: #000000 solid; ;
}

.about h1 {
  font-weight: 200;
  font-size: 2em;
  color: #000000;
}

.about p{
  margin-bottom: 5rem;
  font-weight: 200;
}

@media (max-width: 768px) {
  .about h1 {
    font-size: 1.5rem;
  }

  .about p{
    font-size: 10px;
    margin-bottom: 2rem;
  }
}