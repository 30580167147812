

  /* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100..900&display=swap');


.social-icons button {
  background: none;
  border: none;
  color: #000000;
  cursor: pointer;
  margin: 0 10px;
  font-size: 20px;
}
  
.cards-section{
  margin-top: 10rem;
}

.opportunities-section{
  margin-top: 10rem;
}

.clubhouse-section{
  margin-top: 10rem;
}

.personalization-section{
  margin-top: 10rem;
}

.footer-section {
  padding: 20px 0;
}


  /* Animation Classes */
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.slideUp {
  animation-name: slideUp;
}

.slideDown {
  animation-name: slideDown;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.delay-1s {
  animation-delay: 1s;
}

.delay-2s {
  animation-delay: 2s;
}



@keyframes rotateIn {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
}


  
  @media (max-width: 768px) {

    .footer-section {
      padding: 0 0;
    }
}

