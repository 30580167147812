

.opportunities .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal-width columns */
    gap: 20px; /* Space between the cards */
    padding: 20px;
}

.opportunities .grid-container .gridcard {
  order: 2;
  padding: 8rem;
  margin-top: 5rem;
  text-align: left;
}

.opportunities .grid-container .gridcard1{
  background-image: url('../assets/sections/opportunitiesImg.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  align-items: center;
  height: 70vh;
  order: 1;
}

.opportunities .grid-container .gridcard .title {
  font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    color: #000000;
    margin: 0 0 10px 0;
    font-size: 24px;
  }

  .opportunities .grid-container .gridcard .description {
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    color: #000000;
    margin: 0;
    font-size: 16px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .opportunities .grid-container {
      grid-template-columns: 1fr;
  }

  .opportunities .grid-container .gridcard{
    order: 2;
    padding: 5rem;
  }

  .opportunities .grid-container .gridcard1 {
    order: 1;
    padding: 5rem;
    height: 40vh;
}

  .opportunities .grid-container .gridcard .title {
      font-size: 35px; 

  }

  .opportunities .grid-container .gridcard .description {
      font-size: 25px; 
  }
}

@media (max-width: 768px) {
  .opportunities .grid-container .gridcard,
  .opportunities .grid-container .gridcard1 {
      padding: 3rem;
  }

  .opportunities .grid-container .gridcard1 {
      height: 50vh;
  }

  .opportunities .grid-container .gridcard .title {
      font-size: 18px; 
  }

  .opportunities .grid-container .gridcard .description {
      font-size: 12px; 
  }
}

@media (max-width: 480px) {
  .opportunities .grid-container .gridcard,
  .opportunities .grid-container .gridcard1 {
      padding: 2rem;
  }

  .opportunities .grid-container .gridcard1 {
      height: 26vh; 
  }

  .opportunities .grid-container .gridcard .title {
      font-size: 16px; 
  }

  .opportunities .grid-container .gridcard .description {
      font-size: 10px; 
  }
}